export default class Portfolio {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.dateOpened = data.dateOpened;
    this.commission = data.commission;
    this.description = data.description;
    this.isClosed = data.isClosed;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
