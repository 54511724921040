<template>
  <b-modal
    id="modal-portfolio"
    ref="portfolio-modal"
    :title="title"
    :ok-title="okTitle"
    cancel-variant="outline-secondary"
    cancel-title="Отмена"
    @ok="handleOk"
    button-size="sm"
    no-close-on-backdrop
    size="sm"
  >
    <validation-observer ref="portfolioFormRef">
      <b-form @submit.stop.prevent="handleSubmit">
        <portfolio-form ref="portfolioDataRef" :portfolioId="portfolioId" />
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import PortfolioForm from "@/components/investments/portfolios/PortfolioForm.vue";

export default {
  name: "PortfolioModal",
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    portfolioId: {
      type: String,
      default: null,
    },
  },
  components: {
    ValidationObserver,
    PortfolioForm,
  },
  data() {
    return {
      title: "Новый портфель",
      okTitle: "Добавить",
      okDisable: false,
      isNew: true,
    };
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit() {
      const formValidity = await this.checkFormValidity();
      if (!formValidity) {
        return;
      }

      this.okDisable = true;
      try {
        await this.savePortfolio();
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.portfolioId ? "Портфель изменен" : "Портфель добавлен",
            icon: "ThumbsUpIcon",
            variant: "success",
          },
        });
      } catch (err) {
        // this.$refs.portfolioFormRef.setErrors(err.response.data);
        console.error(err);
        throw err;
      } finally {
        this.okDisable = false;
      }
    },
    checkFormValidity() {
      return this.$refs.portfolioFormRef.validate();
    },
    async savePortfolio() {
      if (this.portfolioId) {
        // update
        await this.$store.dispatch("investments/updatePortfolio", {
          data: this.$refs.portfolioDataRef.form,
        });
      } else {
        // create
        await this.$store.dispatch("investments/createPortfolio", {
          data: this.$refs.portfolioDataRef.form,
        });
      }
      this.$root.$nextTick(() => {
        this.$root.$bvModal.hide("modal-portfolio");
      });
    },
  },
  watch: {
    portfolioId(value) {
      if (value) {
        this.title = "Изменить портфель";
        this.okTitle = "Изменить";
      } else {
        this.title = "Новый портфель";
        this.okTitle = "Добавить";
      }
    },
  },
};
</script>

<style></style>
