<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title>
          <span>{{ data.name }}</span>
          <b-badge v-if="data.isClosed" variant="light-danger" class="mx-1">
            <feather-icon icon="LockIcon" />
          </b-badge>
        </b-card-title>
        <b-card-text class="mb-0">{{ data.description }}</b-card-text>
      </div>
      <div class="card-action">
        <feather-icon
          icon="EyeIcon"
          size="18"
          class="text-muted cursor-pointer"
          @click="showPortfolio(data.id)"
        />
        <b-dropdown variant="link" right no-caret size="sm">
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" class="text-muted align-end" />
          </template>
          <b-dropdown-item @click="editPortfolio(data.id)">
            <feather-icon icon="Edit3Icon" class="mr-50" />
            <span class="align-middle">Изменить</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deletePortfolio(data.id)">
            <feather-icon icon="TrashIcon" class="mr-50" />
            <span class="align-middle">Удалить</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </b-card-header>
    <b-card-body>
      <div class="d-flex justify-content-between">
        <span class="font-weight-bolder">Комиссия</span>
        <span>{{ commissionFormatter(data.commission) }}</span>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { asPercent } from "@/utils/formatter";

export default {
  name: "PotrfoliosCard",
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    editPortfolio(id) {
      this.$emit("openModal", id);
    },
    showPortfolio(id) {
      this.$store.commit("investments/SET_PORTFOLIO", id);
      this.$router.push({ name: "inv-overview" });
    },
    deletePortfolio(id) {
      this.$root.$bvModal
        .msgBoxConfirm("Вы действительно хотите удалить портфель?", {
          cancelVariant: "outline-secondary",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Да",
          cancelTitle: "Нет",
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch("investments/deletePortfolio", { id });
          }
        });
    },
    commissionFormatter(value) {
      return asPercent(value);
    },
  },
};
</script>

<style lang="scss">
.card-action {
  display: flex;

  .dropdown-toggle-no-caret {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0.5rem;
  }
}
</style>
