<template>
  <b-row>
    <portfolio-modal :portfolioId="portfolioId" />
    <b-col cols="12" md="4" v-for="portfolioObj in portfolios" :key="portfolioObj.id">
      <potrfolio-card :data="portfolioObj" @openModal="openModal" />
    </b-col>
    <b-col cols="12" md="4">
      <b-card class="empty-box" @click="openModal(null)">
        <div>
          <feather-icon icon="PlusIcon" size="18" class="text-muted" />
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { ref } from "@vue/composition-api";
import PotrfolioCard from "@/components/investments/portfolios/PotrfolioCard.vue";
import PortfolioModal from "@/components/investments/portfolios/PortfolioModal.vue";

import usePortfolio from "@/comp-functions/usePortfolio";

export default {
  name: "Portfolios",
  components: {
    PotrfolioCard,
    PortfolioModal,
  },
  setup(props, contex) {
    const { portfolios } = usePortfolio();
    const portfolioId = ref(null);

    const openModal = (value) => {
      contex.root.$bvModal.show("modal-portfolio");
      portfolioId.value = value;
    };

    return {
      portfolios,
      openModal,
      portfolioId,
    };
  },
};
</script>

<style></style>
