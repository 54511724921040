<template>
  <b-row>
    <!-- name -->
    <b-col cols="12">
      <b-form-group label="Название" label-for="portfolioName">
        <input-with-validation
          id="portfolioName"
          v-model="form.name"
          rules="required"
          vid="name"
          label="Название"
          size="sm"
        />
      </b-form-group>
    </b-col>

    <!-- dateOpened -->
    <b-col cols="12">
      <b-form-group label="Дата" label-for="dateOpened">
        <date-with-validation
          id="dateOpened"
          v-model="form.dateOpened"
          vid="dateOpened"
          label="Дата открытия"
          size="sm"
        />
      </b-form-group>
    </b-col>

    <!-- commission -->
    <b-col cols="12">
      <b-form-group label="Комиссия" label-for="commission">
        <input-with-validation
          id="commissionBroker"
          v-model="form.commission"
          rules="double:0"
          vid="commission"
          label="Комиссия"
          size="sm"
          :formatter="decimalFormatter"
        />
      </b-form-group>
    </b-col>

    <!-- description -->
    <b-col cols="12">
      <b-form-group label="Описание" label-for="description">
        <b-form-input id="description" v-model="form.description" size="sm" />
      </b-form-group>
    </b-col>

    <!-- closed -->
    <b-col cols="12">
      <b-form-group>
        <b-form-checkbox id="isClosed" v-model="form.isClosed">Закрыт?</b-form-checkbox>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { double, required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import DateWithValidation from "@/components/inputs/DateWithValidation.vue";
import InputWithValidation from "@/components/inputs/InputWithValidation.vue";

import Portfolio from "@/models/PortfolioModel";

export default {
  name: "PortfolioForm",
  components: {
    DateWithValidation,
    InputWithValidation,
  },
  props: {
    portfolioId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      form: new Portfolio({}),
    };
  },
  methods: {
    decimalFormatter(val) {
      return val.replace(",", ".");
    },
  },
  async mounted() {
    if (this.portfolioId) {
      try {
        const response = await this.$store.dispatch("investments/getPortfolioDetail", {
          id: this.portfolioId,
        });
        Object.assign(this.form, response);
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Ошибка загрузки данных",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: "Перезагрузите страницу",
          },
        });
      }
    }
  },
};
</script>

<style></style>
